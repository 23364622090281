<template>
  <div class="container">
    <div class="news_box">
      <div class="title flex_center_between_box">
        <div class="title_txt flex_center_start_box"><img src="../../assets/img/detail/title_ic8.png" />News Media</div>
        <download-excel class="export-excel-wrapper" :data="DetailsForm" :fields="json_fields" :header="title" name="News Media.xls">
          <div class="btn_export flex_center" @click="exportCompany"><img src="../../assets/img/search/export_ic1.png" />Export</div>
        </download-excel>
      </div>
      <div class="right_cont_box">
        <div class="table_box">
          <el-table ref="newsTable" :data="tableData" border style="width: 100%">
            <!-- 空数据状态的插槽 -->
            <template slot="empty">
              <noDate />
            </template>
            <el-table-column label="Date" prop="date" width="150" align="left" show-overflow-tooltip :resizable="false" />
            <el-table-column label="Title" prop="title" width="660" align="left"  show-overflow-tooltip :resizable="false" >
            <template slot-scope="scope">
                                <div v-show="showTranslate" @click="setid(scope.row)" style="width: 640" class="tbl_company line_1">
                                    {{ scope.row.title }}
                                </div>
                                <div v-show="!showTranslate" @click="setid(scope.row)" style="width: 640px" class="tbl_company line_1">
                                    {{ scope.row.title }}
                                </div>
                            </template>
                        </el-table-column>
            <el-table-column label="Sources" prop="sources" width="258" align="left" show-overflow-tooltip :resizable="false" />
            <!-- <el-table-column label="Copyright" prop="type" width="225"  align="left" show-overflow-tooltip :resizable="false" /> -->
 
            <!-- <el-table-column label="Location" prop="location" width="150" align="left" show-overflow-tooltip :resizable="false" class-name="company_link">
              <template slot-scope="scope">
                  <span @click="gotocountry1(scope.row)">{{scope.row.location  }}</span>
                </template>
              
              </el-table-column> -->
            <el-table-column label="Impact" width="80" prop="impact" align="center" show-overflow-tooltip :resizable="false">
              <template slot-scope="scope">
                <div class="flex_center">
                  <el-tooltip v-if="scope.row.impact == '绿'" class="tbl_opr_tip" :visible-arrow="false" effect="light" content="Good" placement="top">
                    <div class="impact_status green">
                      <img src="../../assets/img/detail/tbl_ic1.png" />
                    </div>
                  </el-tooltip>
                  <el-tooltip v-else-if="scope.row.impact == '红'" class="tbl_opr_tip" :visible-arrow="false" effect="light" content="Bad" placement="top">
                    <div class="impact_status red">
                      <img src="../../assets/img/detail/tbl_ic2.png" />
                    </div>
                  </el-tooltip>
                  <div v-else class="tbl_opr_tip">
                    <div class="impact_status blue">
                      <img src="../../assets/img/detail/tbl_ic1.png" />
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="page_i_box">
            <Page :totalFont="true" :total="page.total" @onPageChange="onPageChange" @currtentPageChange="currtentPageChange"></Page>
            <div class="search_rsult_txt line_2">{{ page.pageNo }}-{{ page.pageSize }} of over {{ page.total | FilterNum}} results</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Page from '@/components/page'
import noDate from '@/components/tableNoDate'

import { getNewsMedia } from '@/api/companyApi.js'
import crypto from '@/utils/crypto'
import { mixins1 } from '@/mixins/index';
export default {
  mixins: [mixins1],
  components: { noDate, Page },
  data() {
    return {
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      // searchName: '',
      newsTable: [],
      tableData: [],
      // 下载
      title: 'News Media',
      json_fields: {
        Date: 'date',
        Sources: 'sources',
        Type: 'type',
        Title: 'title',
        Location: 'location',
        Impact: 'impact',
      },
      DetailsForm: [],
    }
  },
  computed: {
        //index传值 是否翻译
        showTranslate: {
            get() {
                return this.$parent.indexIsTranslate;
            },
            set() {},
        },
        curPagePower: {
            get() {
                return JSON.parse(this.$parent.powerToPage);
            },
            set() {},
        },
    },
  watch: {},
  created() {
    this.getData()
  },
  methods: {
    setid(row){
      if (row.titleUrl.includes('http')) {
                    let routeData = row.titleUrl;
                    window.open(routeData, '_blank');
                } else {
                    let routeData = 'http://' + row.titleUrl;
                    window.open(routeData, '_blank');
                }
    },
    gotocountry1(row){
         if(row.location!='n.a.'){
          let routeData= this.$router.resolve({
              path: '/country?'  + '&companyCountry=' + this.$route.query.companyCountry,
          });
    
          window.open(routeData.href, '_blank');
         }
         //  if(!row.countryCode.includes(';')){
  
         //  }
      },
    getData() {
      const id3a = this.$route.query.id3a
      const companyCountry = this.$route.query.companyCountry
      // this.searchName = this.$route.query.searchname ? this.$route.query.searchname : ''
      // let params = 'id3a=156107587326&companyCountry=CN'
      let params = 'id3a=' + id3a + '&companyCountry=' + companyCountry

      getNewsMedia(params).then((result) => {
          let data = JSON.parse(crypto.decrypt(result))
          if (data && data.code && data.data) {
            console.log(`data.data`, data.data);
            // for (const key in data.data) {
            //   const element = data.data[key]
            //   this.newsTable.push(element)
            // }
            this.newsTable = data.data
            this.tableData = this.newsTable ? this.newsTable.slice(0, this.page.pageSize) : []
            this.page.total=this.newsTable.length
          }
      })
    },
    //导出
    exportCompany() {
            if (this.curPagePower[0].power) {
              this.DetailsForm = this.newsTable
            } else {
             this.message()
            }
      
    },
    //分页
    onPageChange(pageNo) {
      this.page.pageNo = pageNo
      if (pageNo == 1) {
        this.tableData = this.newsTable.slice(0, this.page.pageSize)
        return
      }
      const startSize = (pageNo - 1) * this.page.pageSize
      this.tableData = this.newsTable.slice(startSize, startSize + this.page.pageSize)

      // if (!this.tableData.length && pageNo > 1) {
      //   this.onPageChange({ pageNo: this.page.pageNo - 1 })
      // }
    },
    currtentPageChange(pageSize) {
      this.page.pageSize = pageSize
      const pageNo = this.page.pageNo

      if (pageNo == 1) {
        this.tableData = this.newsTable.slice(0, this.page.pageSize)
        return
      }
      const startSize = (pageNo - 1) * this.page.pageSize
      this.tableData = this.newsTable.slice(startSize, startSize + this.page.pageSize)
    },
  },
}
</script>
<style scoped>
.cont_m {
  margin-top: 30px;
  margin-bottom: 80px;
}
.news_box {
  margin-top: 60px;
}
.title {
  margin-bottom: 20px;
}
.title_txt {
  font-size: 16px;
  font-family: 'Arial Bold';
}
.title_txt img {
  width: 20px;
  margin-right: 8px;
}
.impact_status {
  width: 26px;
  height: 26px;
  text-align: center;
  border-radius: 50%;
}
.impact_status img {
  width: 14px;
  height: 14px;
  margin-top: 6px;
}
.impact_status.green {
  background: #55b419;
}
.impact_status.blue {
  background: #1290c9;
}
.impact_status.red {
  background: #ff7600;
}
</style>
